import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { Container, Icon, Menu } from 'semantic-ui-react'

function Dash({ config }) {
	
	return (
		<>
			<Container text style={{ color: "#fff", flex: 1, padding: "2em 0" }}>
				
				<Menu fluid vertical inverted icon="labeled" color="green">
					
					<Menu.Item as={Link} to="/calculator/conveyancing-costs"><Icon name="calculator" size="large" /> Transfer Costs Calculator</Menu.Item>
					<Menu.Item as={Link} to="/calculator/bond-registration-costs"><Icon name="calculator" size="large" /> Bond Registration Costs Calculator</Menu.Item>
					<Menu.Item as={Link} to="/calculator/bond-repayments"><Icon name="calculator" size="large" /> Bond Repayments Calculator</Menu.Item>
					
					<Menu.Item as={Link} to="/calculator/extra-payments"><Icon name="calculator" size="large" /> Extra Payments Calculator</Menu.Item>
					<Menu.Item as={Link} to="/calculator/agent-commission"><Icon name="calculator" size="large" /> Agent Commission Calculator</Menu.Item>
					
				</Menu>
				
				<Menu fluid vertical inverted color="green">
					<Menu.Item as={Link} to="/contact"><Icon name="mail" size="large" /> Contact Us</Menu.Item>
					<Menu.Item as={Link} to="/about"><Icon name="address book outline" size="large" /> About Us</Menu.Item>
					<Menu.Item as={Link} to="/terms"><Icon name="compass outline" size="large" /> Terms of Use</Menu.Item>
				</Menu>
				
			</Container>
		</>
	)
}

const mapStateToProps = state => ({
	config: state.config.config
})

export default connect(
	mapStateToProps,
	null
)(Dash)