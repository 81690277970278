import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Button, Container, Divider, Form, Header, Icon, Label, Table } from 'semantic-ui-react'

import formatNumber from '../../../utils/formatNumber'
import NumberField from '../../../components/NumberField'

const DEFAULT_STATE = {
	calculating: false,
	values: {
		currentBondDebt: 0,
		currentBondRepayment: 0,
		additionalMonthlyRepayment: 0,
		additionalOnceoffRepayment: 0,
		interestRate: 0,
		remainingLoanTerm: 0
	},
	results: {
		monthlyRepayments: {
			value: 0,
			display: formatNumber(0, true),
			variance: formatNumber(0, true)
		},
		remainingLoanTerm: {
			value: 0,
			display: '0',
			variance: '0'
		},
		loanBalance: {
			value: 0,
			display: formatNumber(0, true),
			variance: formatNumber(0, true)
		},
		totalInterest: {
			value: 0,
			display: formatNumber(0, true),
			variance: formatNumber(0, true)
		},
		totalCostOfLoan: {
			value: 0,
			display: formatNumber(0, true),
			variance: formatNumber(0, true)
		}
	}
}

class ExtraPayments extends Component {
	
	state = DEFAULT_STATE;
	
	handleSubmit = (e) => {
		
		this.setState({ calculating: true });
		
		let values = this.state.values;
		
		// INPUT figures needed for calculations
		
		let CurrentBondDebt = parseFloat(values.currentBondDebt),
			CurrentBondRepayment = parseFloat(values.currentBondRepayment),
			AdditionalMonthlyRepayment = parseFloat(values.additionalMonthlyRepayment),
			AdditionalOnceoffRepayment = parseFloat(values.additionalOnceoffRepayment),
			InterestRate = parseFloat(values.interestRate),
			RemainingLoanTerm = parseFloat(values.remainingLoanTerm)
		;
		
		// CALCULATIONS
		
		let MonthlyInterestRate = (InterestRate / 100) / 12;
		
		// CB = Current Breakdown
		// NB = New Breakdown
		// V = Variance
		
		let CB_MonthlyRepayment = CurrentBondRepayment,
			CB_LoanBalance = CurrentBondDebt,
			CB_RemainingLoanTermCalc = RemainingLoanTerm,
			CB_TotalCostOfLoan = CB_MonthlyRepayment * CB_RemainingLoanTermCalc * 12,
			CB_TotalInterest = CB_TotalCostOfLoan - CB_LoanBalance,
			
			NB_MonthlyRepayment = CurrentBondRepayment + AdditionalMonthlyRepayment,
			NB_LoanBalance = CurrentBondDebt - AdditionalOnceoffRepayment,
			NB_RemainingLoanTermCalc = (-Math.log(1 - (NB_LoanBalance * MonthlyInterestRate) / NB_MonthlyRepayment) / Math.log(1 + MonthlyInterestRate)) / 12,
			NB_TotalCostOfLoan = (NB_MonthlyRepayment * NB_RemainingLoanTermCalc * 12) + AdditionalOnceoffRepayment,
			NB_TotalInterest = NB_TotalCostOfLoan - (NB_LoanBalance + AdditionalOnceoffRepayment),
			
			V_MonthlyRepayment = NB_MonthlyRepayment - CB_MonthlyRepayment,
			V_LoanBalance = CB_LoanBalance - NB_LoanBalance,
			V_RemainingLoanTermCalc = CB_RemainingLoanTermCalc - NB_RemainingLoanTermCalc,
			V_TotalCostOfLoan = CB_TotalCostOfLoan - NB_TotalCostOfLoan,
			V_TotalInterest = CB_TotalInterest - NB_TotalInterest
		;
		
		let updatedResults = {
			monthlyRepayments: {
				value: NB_MonthlyRepayment,
				display: formatNumber(NB_MonthlyRepayment, true),
				variance: formatNumber(V_MonthlyRepayment, true)
			},
			remainingLoanTerm: {
				value: NB_RemainingLoanTermCalc,
				display: formatNumber(NB_RemainingLoanTermCalc, false),
				variance: formatNumber(V_RemainingLoanTermCalc, false)
			},
			loanBalance: {
				value: NB_LoanBalance,
				display: formatNumber(NB_LoanBalance, true),
				variance: formatNumber(V_LoanBalance, true)
			},
			totalInterest: {
				value: NB_TotalInterest,
				display: formatNumber(NB_TotalInterest, true),
				variance: formatNumber(V_TotalInterest, true)
			},
			totalCostOfLoan: {
				value: NB_TotalCostOfLoan,
				display: formatNumber(NB_TotalCostOfLoan, true),
				variance: formatNumber(V_TotalCostOfLoan, true)
			}
		}
		
		this.setState({ results: updatedResults });
		this.setState({ calculating: false });
	}
	refreshForm = () => {
		this.setState(prevState => (DEFAULT_STATE));
	}
	handleChange = (e, data) => {
		
		let updateValues = {
			currentBondDebt: this.state.values.currentBondDebt,
			currentBondRepayment: this.state.values.currentBondRepayment,
			additionalMonthlyRepayment: this.state.values.additionalMonthlyRepayment,
			additionalOnceoffRepayment: this.state.values.additionalOnceoffRepayment,
			interestRate: this.state.values.interestRate,
			remainingLoanTerm: this.state.values.remainingLoanTerm
		};
		
		if (data.name === 'CurrentBondDebt') {
			updateValues.currentBondDebt = e.target.value;
		} else if (data.name === 'CurrentBondRepayment') {
			updateValues.currentBondRepayment = e.target.value;
		} else if (data.name === 'AdditionalMonthlyRepayment') {
			updateValues.additionalMonthlyRepayment = e.target.value;
		} else if (data.name === 'AdditionalOnceoffRepayment') {
			updateValues.additionalOnceoffRepayment = e.target.value;
		} else if (data.name === 'InterestRate') {
			updateValues.interestRate = e.target.value;
		} else if (data.name === 'RemainingLoanTerm') {
			updateValues.remainingLoanTerm = e.target.value;
		}
		
		this.setState({ values: updateValues });
	}
	
	render() {
		
		const { calculating, values, results } = this.state;
		
		if (window.cordova) {
			document.onkeypress = function (e) {
				e = e || window.event;
				if (e.keyCode === 13) {
					window.Keyboard.hide();
				}
			};
		}
		
		return (
			
			<>
				<Container style={{ color: '#fff', flex: 1, padding: '2em 0' }}>
					
					<Header as='h1' inverted style={{ marginbottom: '1em' }}>Extra Payments Calculator</Header>
					
					<Form inverted onSubmit={this.handleSubmit} onReset={this.refreshForm}>
						<Form.Field>
							<NumberField
								name='CurrentBondDebt'
								value={values.currentBondDebt ? values.currentBondDebt : ''}
								placeholder='Current Bond Debt'
								label='Current Bond Debt'
								labelPosition='right'
								labelStart='R'
								onChange={this.handleChange}
							/>
						</Form.Field>
						<Form.Field>
							<NumberField
								name='CurrentBondRepayment'
								value={values.currentBondRepayment ? values.currentBondRepayment : ''}
								placeholder='Current Bond Repayment'
								label='Current Bond Repayment'
								labelPosition='right'
								labelStart='R'
								onChange={this.handleChange}
							/>
						</Form.Field>
						<Form.Field>
							<NumberField
								name='AdditionalMonthlyRepayment'
								value={values.additionalMonthlyRepayment ? values.additionalMonthlyRepayment : ''}
								placeholder='Additional Monthly Repayment'
								label='Additional Monthly Repayment'
								labelPosition='right'
								labelStart='R'
								onChange={this.handleChange}
							/>
						</Form.Field>
						<Form.Field>
							<NumberField
								name='AdditionalOnceoffRepayment'
								value={values.additionalOnceoffRepayment ? values.additionalOnceoffRepayment : ''}
								placeholder='Additional Once-off Repayment'
								label='Additional Once-off Repayment'
								labelPosition='right'
								labelStart='R'
								onChange={this.handleChange}
							/>
						</Form.Field>
						<Form.Field>
							<NumberField
								name='InterestRate'
								value={values.interestRate ? values.interestRate : ''}
								placeholder='Interest Rate'
								label='Interest Rate'
								labelPosition='right'
								labelEnd='%'
								onChange={this.handleChange}
							/>
						</Form.Field>
						<Form.Field>
							<NumberField
								name='RemainingLoanTerm'
								value={values.remainingLoanTerm ? values.remainingLoanTerm : ''}
								placeholder='Remaining Loan Term'
								label='Remaining Loan Term'
								labelPosition='right'
								labelEnd='Years'
								onChange={this.handleChange}
							/>
						</Form.Field>
						<Button.Group>
							<Button icon labelPosition='left' color="grey" type='reset'>
								<Icon name='arrow left' />
								Clear
							</Button>
							<Button icon labelPosition='left' color="green" type='submit' loading={calculating}>
								<Icon name='calculator' />
								Calculate
							</Button>
						</Button.Group>
					</Form>
					
					<Divider horizontal inverted style={{ margin: '2em 0' }}>Results</Divider>
					
					<Table celled unstackable>
						<Table.Body>
							<Table.Row>
								<Table.Cell>
									<Label ribbon color="green">Monthly Repayment</Label>
								</Table.Cell>
								<Table.Cell><strong>{results.monthlyRepayments.display}</strong><br /><small>({results.monthlyRepayments.variance})</small></Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>
									<Label ribbon color="green">Remaining Loan Term</Label>
								</Table.Cell>
								<Table.Cell><strong>{results.remainingLoanTerm.display}</strong><br /><small>({results.remainingLoanTerm.variance})</small></Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>
									<Label ribbon color="green">Loan Balance</Label>
								</Table.Cell>
								<Table.Cell><strong>{results.loanBalance.display}</strong><br /><small>({results.loanBalance.variance})</small></Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>
									<Label ribbon color="green">Total Interest</Label>
								</Table.Cell>
								<Table.Cell><strong>{results.totalInterest.display}</strong><br /><small>({results.totalInterest.variance})</small></Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>
									<Label ribbon color="green">Total Cost of Loan</Label>
								</Table.Cell>
								<Table.Cell><strong>{results.totalCostOfLoan.display}</strong><br /><small>({results.totalCostOfLoan.variance})</small></Table.Cell>
							</Table.Row>
						</Table.Body>
					</Table>
					
				</Container>
			</>
		)
	}
}

const mapStateToProps = state => ({
	config: state.config.config
})

export default connect(
	mapStateToProps,
	null
)(ExtraPayments)
