import React from 'react'
import { connect } from 'react-redux'

import { Container, Divider, Header } from 'semantic-ui-react'

import ContactForm from '../components/ContactForm'
import ShareButton from '../components/ShareButton'

function Contact({ config }) {
	
	return (
		<>
			<Container text style={{ color: '#fff', flex: 1, padding: '2em 0 0' }}>
				
				<Header as="h1" inverted style={{ marginbottom: '1em' }}>Contact Us</Header>
				
				<ContactForm />
				
				<Divider />
				
				<Header as="h3" inverted style={{ marginbottom: '1em' }}>Contact Us Now At:</Header>
				
				<p><strong>MGF Attorneys</strong></p>
				<p><strong>E-mail:</strong> <a href="mailto:mike@mgfattorneys.co.za">mike@mgfattorneys.co.za</a></p>
				<p><strong>Tel:</strong> +27 33 343 1100</p>
				<p><strong>Cell:</strong> +27 82 800 5257</p>
				<p><strong>Fax:</strong> 086 669 3214</p>
				<p><strong>Postal:</strong> P O Box 347, Hilton, 3245, KwaZulu Natal, South Africa</p>
				<p><strong>Physical :</strong> 9 Vlei Road, Hilton, 3245</p>
				
				<Divider />
				
				<ShareButton />
				
			</Container>
		</>
	)
}

const mapStateToProps = state => ({
	config: state.config.config
})

export default connect(
	mapStateToProps,
	null
)(Contact)
