import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Button, Header, Icon, Modal } from 'semantic-ui-react'

class ModalBox extends Component {
	
	state = { open: this.props.open }
	
	close = () => this.setState({ open: false })
	
	render() {
		
		const { open } = this.state
		
		return (
			<Modal basic dimmer="blurring" open={open} onClose={this.close}>
				<Header icon={this.props.headerIcon} content={this.props.headerContent} />
				<Modal.Content>
					<p>{this.props.content}</p>
				</Modal.Content>
				<Modal.Actions>
					<Button basic color="green" inverted onClick={this.close}>
						<Icon name='remove' /> No
					</Button>
					<Button as='a' color='green' inverted href={this.props.href}>
						<Icon name='checkmark' /> Yes
					</Button>
				</Modal.Actions>
			</Modal>
		)
	}
}

const mapStateToProps = state => ({
	config: state.config.config
})

export default connect(
	mapStateToProps,
	null
)(ModalBox)