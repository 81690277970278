import React, { Component } from 'react'

import { Button, Container, Divider, Form, Header, Icon, Label, Table } from 'semantic-ui-react'

import formatNumber from '../../../utils/formatNumber'

import ModalBox from '../../../components/ModalBox'
import NumberField from '../../../components/NumberField'

const DEFAULT_STATE = {
	calculating: false,
	values: {
		loanAmount: 0,
		interestRate: 0,
		repaymentPeriod: 0
	},
	results: {
		monthlyRepayments: {
			value: 0,
			display: formatNumber(0, true)
		},
		totalPayments: {
			value: 0,
			display: '0'
		}
	},
	openModal: false
}

class BondRepayments extends Component {
	
	state = DEFAULT_STATE;
	
	handleSubmit = (e) => {
		
		this.setState({ calculating: true });
		
		let values = this.state.values;
		
		let LoanAmount = parseFloat(values.loanAmount),
			InterestRate = parseFloat(values.interestRate),
			RepaymentPeriod = parseFloat(values.repaymentPeriod)
		;
		
		let TotalPayments = RepaymentPeriod * 12,
			MonthlyInterestRate = (InterestRate / 100) / 12
		;
		
		let calcA = (MonthlyInterestRate * (Math.pow((1 + MonthlyInterestRate), TotalPayments))),
			calcB = (Math.pow((1 + MonthlyInterestRate), TotalPayments)) - 1
		;
		
		let MonthlyRepayment = LoanAmount * (calcA / calcB);
		
		let updatedResults = {
			monthlyRepayments: {
				value: MonthlyRepayment,
				display: formatNumber(MonthlyRepayment, true)
			},
			totalPayments: {
				value: TotalPayments,
				display: formatNumber(TotalPayments, false)
			}
		}
		
		this.setState({ results: updatedResults });
		this.setState({ calculating: false });
	}
	refreshForm = () => {
		this.setState(prevState => (DEFAULT_STATE));
	}
	handleChange = (e, data) => {
		
		let updateValues = {
			loanAmount: this.state.values.loanAmount,
			interestRate: this.state.values.interestRate,
			repaymentPeriod: this.state.values.repaymentPeriod
		};
		
		if (data.name === 'LoanAmount') {
			updateValues.loanAmount = e.target.value;
		} else if (data.name === 'InterestRate') {
			updateValues.interestRate = e.target.value;
		} else if (data.name === 'RepaymentPeriod') {
			updateValues.repaymentPeriod = e.target.value;
		}
		
		this.setState({ values: updateValues });
	}
	
	render() {
		
		const { calculating, values, results, openModal } = this.state;
		
		if (window.cordova) {
			document.onkeypress = function (e) {
				e = e || window.event;
				if (e.keyCode === 13) {
					window.Keyboard.hide();
				}
			};
		}
		
		return (
			
			<>
				<Container style={{ color: '#fff', flex: 1, padding: '2em 0' }}>
					
					<Header as='h1' inverted style={{ marginbottom: '1em' }}>Bond Repayments Calculator</Header>
					
					<Form inverted onSubmit={this.handleSubmit} onReset={this.refreshForm}>
						<Form.Field>
							<NumberField
								name='LoanAmount'
								value={values.loanAmount ? values.loanAmount : ''}
								placeholder='Loan Amount'
								label='Loan Amount'
								labelPosition='right'
								labelStart='R'
								onChange={this.handleChange}
							/>
						</Form.Field>
						<Form.Group widths='equal'>
							<NumberField
								name='InterestRate'
								value={values.interestRate ? values.interestRate : ''}
								placeholder='Interest Rate'
								label='Interest Rate'
								labelPosition='right'
								labelEnd='%'
								onChange={this.handleChange}
								fluid={true}
							/>
							<NumberField
								name='RepaymentPeriod'
								value={values.repaymentPeriod ? values.repaymentPeriod : ''}
								placeholder='Repayment Period'
								label='Repayment Period'
								labelPosition='right'
								labelEnd='Years'
								onChange={this.handleChange}
								fluid={true}
							/>
						</Form.Group>
						<Button.Group>
							<Button icon labelPosition='left' color="grey" type='reset'>
								<Icon name='arrow left' />
								Clear
							</Button>
							<Button icon labelPosition='left' color="green" type='submit' loading={calculating}>
								<Icon name='calculator' />
								Calculate
							</Button>
						</Button.Group>
					</Form>
					
					<Divider horizontal inverted style={{ margin: '2em 0' }}>Results</Divider>
					
					<Table celled unstackable>
						<Table.Body>
							<Table.Row>
								<Table.Cell>
									<Label ribbon color="green">Monthly Repayment</Label>
								</Table.Cell>
								<Table.Cell><strong>{results.monthlyRepayments.display}</strong></Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>
									<Label ribbon color="green">Total Payments</Label>
								</Table.Cell>
								<Table.Cell><strong>{results.totalPayments.display}</strong></Table.Cell>
							</Table.Row>
						</Table.Body>
					</Table>
					
				</Container>
				<ModalBox open={openModal} content="Hello World" />
			</>
		)
	}
}

export default BondRepayments