import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Button, Checkbox, Container, Divider, Form, Header, Icon, Label, Table } from 'semantic-ui-react'

import formatNumber from '../../../utils/formatNumber'
import NumberField from '../../../components/NumberField'

const DEFAULT_STATE = {
	calculating: false,
	values: {
		purchasePrice: 0,
		individual: 'on',
		corporate: 'off'
	},
	display: {
		resultSetType: 'individual'
	},
	results: {
		individual: {
			deedsOfficeFee: { title: 'Deeds Office Fee', 	value: 0, display: formatNumber(0, true) },
			fica: 			{ title: 'FICA', 				value: 0, display: formatNumber(0, true) },
			transferDuty: 	{ title: 'Transfer Duty', 		value: 0, display: formatNumber(0, true) },
			transferFee: 	{ title: 'Transfer Fee', 		value: 0, display: formatNumber(0, true) },
			adminFee: 		{ title: 'Admin Costs',			value: 0, display: formatNumber(0, true) },
			VAT: 			{ title: 'VAT', 				value: 0, display: formatNumber(0, true) },
			total: 			{ title: 'Total', 				value: 0, display: formatNumber(0, true) }
		},
		corporate: {
			deedsOfficeFee: { title: 'Deeds Office Fee', 	value: 0, display: formatNumber(0, true) },
			fica: 			{ title: 'FICA', 				value: 0, display: formatNumber(0, true) },
			transferDuty: 	{ title: 'Transfer Duty', 		value: 0, display: formatNumber(0, true) },
			transferFee: 	{ title: 'Transfer Fee', 		value: 0, display: formatNumber(0, true) },
			adminFee: 		{ title: 'Admin Fee',			value: 0, display: formatNumber(0, true) },
			VAT: 			{ title: 'VAT', 				value: 0, display: formatNumber(0, true) },
			total: 			{ title: 'Total', 				value: 0, display: formatNumber(0, true) }
		}
	}
}

class ConveyancingCosts extends Component {
	
	state = DEFAULT_STATE;
	
	handleSubmit = (e) => {
		
		this.setState({ calculating: true });
		
		let values = this.state.values;
		
		let config = this.props.config.getElementsByTagName('ALL')[0];
		
		// STATIC figures NOT needed for calculations
		
		let Sundries = parseFloat(config.getElementsByTagName('SUNDRIES')[0].value),
			Fica = parseFloat(config.getElementsByTagName('FICA')[0].value)
		;
		
		// STATIC figures needed for calculations
		
		let VATpercent = parseFloat(config.getElementsByTagName('VAT')[0].value);
		
		// INPUT figures needed for calculations
		
		let PurchasePrice = parseFloat(values.purchasePrice);
		
		// CALCULATIONS
		
		// Deeds Office Fee
		
		let DeedsOfficeFee = 0;
		
		if (100000 >= PurchasePrice) {
			 DeedsOfficeFee = 39;
		} else if ((100000 < PurchasePrice) && (PurchasePrice <= 200000)) {
			 DeedsOfficeFee = 86;
		} else if ((200000 < PurchasePrice) && (PurchasePrice <= 300000)) {
			 DeedsOfficeFee = 539;
		} else if ((300000 < PurchasePrice) && (PurchasePrice <= 600000)) {
			 DeedsOfficeFee = 673;
		} else if ((600000 < PurchasePrice) && (PurchasePrice <= 800000)) {
			 DeedsOfficeFee = 946;
		} else if ((800000 < PurchasePrice) && (PurchasePrice <= 1000000)) {
			 DeedsOfficeFee = 1086;
		} else if ((1000000 < PurchasePrice) && (PurchasePrice <= 2000000)) {
			 DeedsOfficeFee = 1220;
		} else if ((2000000 < PurchasePrice) && (PurchasePrice <= 4000000)) {
			 DeedsOfficeFee = 1691;
		} else if ((4000000 < PurchasePrice) && (PurchasePrice <= 6000000)) {
			 DeedsOfficeFee = 2051;
		} else if ((6000000 < PurchasePrice) && (PurchasePrice <= 8000000)) {
			 DeedsOfficeFee = 2442;
		} else if ((8000000 < PurchasePrice) && (PurchasePrice <= 10000000)) {
			 DeedsOfficeFee = 2854;
		} else if ((10000000 < PurchasePrice) && (PurchasePrice <= 15000000)) {
			 DeedsOfficeFee = 3397;
		} else if ((15000000 < PurchasePrice) && (PurchasePrice <= 20000000)) {
			 DeedsOfficeFee = 4080;
		} else if (20000000 < PurchasePrice) {
			 DeedsOfficeFee = 5435;
		}
		
		// Transfer Duty
		
		let TransferDuty = 0;
		
		if (1000000 > PurchasePrice) {
			TransferDuty = 0;
		} else if ((1000001 <= PurchasePrice) && (PurchasePrice <= 1375000)) {
			TransferDuty = (PurchasePrice - 1000000) * 0.03;
		} else if ((1375001 < PurchasePrice) && (PurchasePrice <= 1925000)) {
			TransferDuty = (PurchasePrice - 1375000) * 0.06 + 11250;
		} else if ((1925001 < PurchasePrice) && (PurchasePrice <= 2475000)) {
			TransferDuty = (PurchasePrice - 1925000) * 0.08 + 44250;
		} else if ((2475001 < PurchasePrice) && (PurchasePrice <= 11000000)) {
			TransferDuty = (PurchasePrice - 2475000) * 0.11 + 88250;
		} else if (PurchasePrice > 10000001) {
			TransferDuty = (PurchasePrice - 11000000) * 0.13 + 1026000;
		}
		
		// Transfer Fee
		
		let TransferFee = 0;
		
		if (100000 >= PurchasePrice) {
			TransferFee = 5200;
		} else if ((100000 < PurchasePrice) && (PurchasePrice <= 500000)) {
			TransferFee = Math.ceil((PurchasePrice - 100000) / 50000) * 800 + 5200;
		} else if ((500000 < PurchasePrice) && (PurchasePrice <= 1000000)) {
			TransferFee = Math.ceil((PurchasePrice - 500000) / 100000) * 1600 + 11600;
		} else if ((1000000 < PurchasePrice) && (PurchasePrice <= 5000000)) {
			TransferFee = Math.ceil((PurchasePrice - 1000000) / 200000) * 1600 + 19600;
		} else if (5000000 < PurchasePrice) {
			TransferFee = Math.ceil((PurchasePrice - 5000000) / 500000) * 2000 + 51600;
		}
		
		// Vat
		
		let VAT = (TransferFee + Sundries) * VATpercent / 100;
		
		// Total
		
		let Total = DeedsOfficeFee + Fica + TransferDuty + TransferFee + Sundries + VAT;
		
		let updatedResults = {
			individual: {
				deedsOfficeFee: { title: 'Deeds Office Fee', 	value: DeedsOfficeFee, 		display: formatNumber(DeedsOfficeFee, true) },
				fica: 			{ title: 'FICA',	 			value: Fica, 				display: formatNumber(Fica, true) },
				transferDuty: 	{ title: 'Transfer Duty', 		value: TransferDuty, 		display: formatNumber(TransferDuty, true) },
				transferFee: 	{ title: 'Transfer Fee', 		value: TransferFee, 		display: formatNumber(TransferFee, true) },
				adminFee: 		{ title: 'Admin Costs', 		value: Sundries, 			display: formatNumber(Sundries, true) },
				VAT: 			{ title: 'VAT', 				value: VAT, 				display: formatNumber(VAT, true) },
				total: 			{ title: 'Total', 				value: Total, 				display: formatNumber(Total, true) }
			},
			corporate: {
				deedsOfficeFee: { title: 'Deeds Office Fee', 	value: DeedsOfficeFee, 		display: formatNumber(DeedsOfficeFee, true) },
				fica: 			{ title: 'FICA',	 			value: Fica, 				display: formatNumber(Fica, true) },
				transferDuty: 	{ title: 'Transfer Duty', 		value: TransferDuty, 		display: formatNumber(TransferDuty, true) },
				transferFee: 	{ title: 'Transfer Fee', 		value: TransferFee, 		display: formatNumber(TransferFee, true) },
				adminFee: 		{ title: 'Admin Costs', 		value: Sundries, 			display: formatNumber(Sundries, true) },
				VAT: 			{ title: 'VAT', 				value: VAT, 				display: formatNumber(VAT, true) },
				total: 			{ title: 'Total', 				value: Total, 				display: formatNumber(Total, true) }
			}
		}
		
		this.setState({ results: updatedResults });
		
		let displayValue = {
			resultSetType: values.individual === 'on' ? 'individual' : 'corporate'
		};
		
		this.setState({ display: displayValue });
		
		this.setState({ calculating: false });
	}
	refreshForm = () => {
		this.setState(prevState => (DEFAULT_STATE));
	}
	handleChange = (e, data) => {
		
		let updateValues = {
			purchasePrice: this.state.values.purchasePrice,
			individual: this.state.values.individual,
			corporate: this.state.values.corporate
		};
		let submitYN = true;
		
		if (data.name === 'PurchasePrice') {
			
			updateValues.purchasePrice = e.target.value;
			submitYN = false;
			
		} else if (data.name === 'Individual') {
			if (data.checked === true) {
				updateValues.individual = 'on';
				updateValues.corporate = 'off';
			} else {
				updateValues.individual = 'off';
				updateValues.corporate = 'on';
			}
		} else if (data.name === 'Corporate') {
			if (data.checked === true) {
				updateValues.individual = 'off';
				updateValues.corporate = 'on';
			} else {
				updateValues.individual = 'on';
				updateValues.corporate = 'off';
			}
		}
		
		this.setState(prevState => ({ values: updateValues }), () => submitYN === true ? this.handleSubmit() : null);
	}
	
	render() {
		
		const { calculating, values, display, results } = this.state;
		
		if (window.cordova) {
			document.onkeypress = function (e) {
				e = e || window.event;
				if (e.keyCode === 13) {
					window.Keyboard.hide();
				}
			};
		}
		
		return (
			
			<Container text style={{ color: '#fff', flex: 1, padding: '2em 0' }}>
				
				<Header as='h1' inverted style={{ marginbottom: '1em' }}>Conveyancing Costs Calculator</Header>
				
				<Form inverted onSubmit={this.handleSubmit} onReset={this.refreshForm}>
					<Form.Field>
						<NumberField
							name='PurchasePrice'
							value={values.purchasePrice ? values.purchasePrice : ''}
							placeholder='Purchase Price'
							label='Purchase Price'
							labelPosition='right'
							labelStart='R'
							onChange={this.handleChange}
						/>
					</Form.Field>
					<Form.Field>
						<Checkbox
							toggle
							label="Individual"
							name="Individual"
							value={values.individual ? values.individual : 'on'}
							checked={values.individual === "on"}
							onChange={this.handleChange}
						/>
					</Form.Field>
					<Form.Field>
						<Checkbox
							toggle
							label="Corporate"
							name="Corporate"
							value={values.corporate ? values.corporate : 'on'}
							checked={values.corporate === "on"}
							onChange={this.handleChange}
						/>
					</Form.Field>
					<Button.Group>
						<Button icon labelPosition='left' color="grey" type='reset'>
							<Icon name='arrow left' />
							Clear
						</Button>
						<Button icon labelPosition='left' color="green" type='submit' loading={calculating}>
							<Icon name='calculator' />
							Calculate
						</Button>
					</Button.Group>
				</Form>
				{display.resultSetType === 'individual' ? (
					<>
						<Divider horizontal inverted style={{ margin: '2em 0' }}>Individual Results</Divider>
						<Table celled unstackable singleLine size="small">
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell></Table.HeaderCell>
									<Table.HeaderCell>Cost</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								
								{Object.keys(results.individual).map((key, index) => {
									return <Table.Row key={index}>
												<Table.Cell>
													<Label ribbon color="green">{ results.individual[key].title }</Label>
												</Table.Cell>
												<Table.Cell><strong>{ results.individual[key].display }</strong></Table.Cell>
											</Table.Row>
								})}
								
							</Table.Body>
						</Table>
					</>
				) : (
					<>
						<Divider horizontal inverted style={{ margin: '2em 0' }}>Corporate Results</Divider>
						<Table celled unstackable singleLine size="small">
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell></Table.HeaderCell>
									<Table.HeaderCell>Cost</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								
								{Object.keys(results.corporate).map((key, index) => {
									return <Table.Row key={index}>
												<Table.Cell>
													<Label ribbon color="green">{ results.corporate[key].title }</Label>
												</Table.Cell>
												<Table.Cell><strong>{ results.corporate[key].display }</strong></Table.Cell>
											</Table.Row>
								})}
								
							</Table.Body>
						</Table>
					</>
				)}
			</Container>
		)
	}
}

const mapStateToProps = state => ({
	config: state.config.config
})

export default connect(
	mapStateToProps,
	null
)(ConveyancingCosts)
