const formatNumber = (number, symbolYN) => {
	
	let result = 0;
	number = number ? number : 0;
	
	if (symbolYN) {
		
		let symbol = 'R';
		
		if (number) {
			let formatter = new Intl.NumberFormat("en-ZA", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 });
			result = symbol + ' ' + formatter.format(number);
		} else {
			result = symbol + ' 0,00';
		}
	} else {
		let formatter = new Intl.NumberFormat("en-ZA", { style: "decimal", maximumFractionDigits: 2 });
		result = formatter.format(number);
	}
	return result;
}
export default formatNumber