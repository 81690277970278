import React from 'react'
import { connect } from 'react-redux'

import { Container, Header } from 'semantic-ui-react'

function Terms({ config }) {
	
	return (
		<>
			<Container text style={{ color: '#fff', flex: 1, padding: '2em 0' }}>
				
				<Header as="h1" inverted style={{ marginBottom: '1em' }}>Terms of Use and Privacy Policy</Header>
				
				<p>This is MGF Attorneys mobile app. The address for any correspondence relating to this app is mike@mgfattorneys.co.za. The use of this app is subject to the following terms and conditions:</p>
				
				<Header as="h3" inverted>NOT LEGAL ADVICE</Header>
				<p>The material contained on this app is intended to provide general information on matters of interest. The material does not constitute legal advice or other professional advice. You should seek professional advice before taking action on any material contained on this app. The calculators in this app are based on the recommended costs published in the appropriate Acts at the time of development. They do not reflect charges for any additional components and registrations such as exclusive use areas, and should be used as a guide only. They also do not provide for the actual rates which will be payable on the property or any bank administration charges.</p>
				
				<Header as="h3" inverted>LIMITATION OF LIABILITY</Header>
				<p>MGF Attorneys excludes, to the extent lawfully permitted, all liability whatsoever arising out of the use of this app or reliance on the material contained on this app. MGF Attorneys has taken reasonable measures to ensure the integrity of this app and the material contained on this app, but gives no warranty, whether express or implied, that the app and material is free of viruses, trojans, bombs or other malicious code which has the ability to corrupt or affect the operation of your system.</p>
				
				<Header as="h3" inverted>INTELLECTUAL PROPERTY</Header>
				<p>All of the intellectual property rights in material contained on this app, and in the design of this app, including without limitation trademarks, trade names and copyright are the property of MGF Attorneys unless otherwise indicated.</p>
				
				<Header as="h3" inverted>APPLICABLE LAW</Header>
				<p>These terms and conditions shall be governed by and construed in accordance with the laws of the Republic of South Africa. You hereby consent to the exclusive jurisdiction of the High Court of the Republic of South Africa (Natal Provincial Division) in respect of any disputes arising in connection with this app.</p>
				
			</Container>
		</>
	)
}

const mapStateToProps = state => ({
	config: state.config.config
})

export default connect(
	mapStateToProps,
	null
)(Terms)
