import React from 'react'
import { connect } from 'react-redux'

import { Container, Header } from 'semantic-ui-react'

import VersionNumber from '../components/VersionNumber'

function About({ config }) {
	
	return (
		<>
			<Container text style={{ color: '#fff', flex: 1, padding: '2em 0' }}>
				
				<Header as="h1" inverted style={{ marginbottom: '1em' }}>About Mike Forsyth</Header>
				
				<p>The Sole proprietor of the company, Mike Forsyth commenced practise in 1988. He is a fourth generation attorney in the Pietermaritzburg Area. His areas of expertise include amongst others :</p>
				<p>Conveyancing including property development, sectional title schemes, private mortgage bonds, township development, servitudes, leases, rezoning applications, liaising with government departments to smooth the process.</p>
				<p>Trusts and Estates including the formation and registration of inter vivos trusts, drafting of wills and finalisation of estates.</p>
				<p>Commercial Contracts, sale agreements, sale of businesses, formation of companies, cessions, acknowledgments of debt, leases both residential and commercial, sectional title development agreements, joint ventures and franchises.</p>
				
				<VersionNumber />
				
			</Container>
		</>
	)
}

const mapStateToProps = state => ({
	config: state.config.config
})

export default connect(
	mapStateToProps,
	null
)(About)
