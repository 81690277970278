import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Button, Container, Divider, Form, Header, Icon, Label, Table } from 'semantic-ui-react'

import formatNumber from '../../../utils/formatNumber'
import NumberField from '../../../components/NumberField'

const DEFAULT_STATE = {
	calculating: false,
	values: {
		propertyPrice: 0,
		estateAgency: 0
	},
	results: {
		commission: {
			value: 0,
			display: formatNumber(0, true)
		},
		VAT: {
			value: 0,
			display: formatNumber(0, true)
		},
		NETT: {
			value: 0,
			display: formatNumber(0, true)
		}
	}
}

class AgentCommission extends Component {
	
	state = DEFAULT_STATE;
	
	handleSubmit = (e) => {
		
		this.setState({ calculating: true });
		
		let values = this.state.values;
		let config = this.props.config;
		
		// STATIC figures needed for calculations
		
		let VATpercent = parseFloat(config.getElementsByTagName('VAT')[0].value);
		
		// INPUT figures needed for calculations
		
		let PropertyPrice = parseFloat(values.propertyPrice),
			EstateAgency = parseFloat(values.estateAgency)
		;
		
		// CALCULATIONS
		
		let commission = PropertyPrice * EstateAgency / 100,
			VAT = commission * VATpercent / 100,
			NETT = PropertyPrice - commission - VAT
		;
		
		let updatedResults = {
			commission: {
				value: commission,
				display: formatNumber(commission, true)
			},
			VAT: {
				value: VAT,
				display: formatNumber(VAT, true)
			},
			NETT: {
				value: NETT,
				display: formatNumber(NETT, true)
			}
		}
		
		this.setState({ results: updatedResults });
		this.setState({ calculating: false });
	}
	refreshForm = () => {
		this.setState(prevState => (DEFAULT_STATE));
	}
	handleChange = (e, data) => {
		
		let updateValues = {
			propertyPrice: this.state.values.propertyPrice,
			estateAgency: this.state.values.estateAgency
		};
		
		if (data.name === 'PropertyPrice') {
			updateValues.propertyPrice = e.target.value;
		} else if (data.name === 'EstateAgency') {
			updateValues.estateAgency = e.target.value;
		}
		
		this.setState({ values: updateValues });
	}
	
	render() {
		
		const { calculating, values, results } = this.state;
		
		if (window.cordova) {
			document.onkeypress = function (e) {
				e = e || window.event;
				if (e.keyCode === 13) {
					window.Keyboard.hide();
				}
			};
		}
		
		return (
			
			<>
				<Container style={{ color: '#fff', flex: 1, padding: '2em 0' }}>
					
					<Header as='h1' inverted style={{ marginbottom: '1em' }}>Agent Commission Calculator</Header>
					
					<Form inverted onSubmit={this.handleSubmit} onReset={this.refreshForm}>
						<Form.Field>
							<NumberField
								name='PropertyPrice'
								value={values.propertyPrice ? values.propertyPrice : ''}
								placeholder='Property Price'
								label='Property Price'
								labelPosition='right'
								labelStart='R'
								onChange={this.handleChange}
							/>
						</Form.Field>
						<Form.Field>
							<NumberField
								name='EstateAgency'
								value={values.estateAgency ? values.estateAgency : ''}
								placeholder='Estate Agency Commission (%)'
								label='Estate Agency Commission (%)'
								labelPosition='right'
								labelEnd='%'
								onChange={this.handleChange}
								fluid={true}
							/>
						</Form.Field>
						<Button.Group>
							<Button icon labelPosition='left' color="grey" type='reset'>
								<Icon name='arrow left' />
								Clear
							</Button>
							<Button icon labelPosition='left' color="green" type='submit' loading={calculating}>
								<Icon name='calculator' />
								Calculate
							</Button>
						</Button.Group>
					</Form>
					
					<Divider horizontal inverted style={{ margin: '2em 0' }}>Results</Divider>
					
					<Table celled unstackable>
						<Table.Body>
							<Table.Row>
								<Table.Cell>
									<Label ribbon color="green">Estate Agency Commission</Label>
								</Table.Cell>
								<Table.Cell><strong>{results.commission.display}</strong></Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>
									<Label ribbon color="green">VAT on Commission</Label>
								</Table.Cell>
								<Table.Cell><strong>{results.VAT.display}</strong></Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>
									<Label ribbon color="green">Nett to Client</Label>
								</Table.Cell>
								<Table.Cell><strong>{results.NETT.display}</strong></Table.Cell>
							</Table.Row>
						</Table.Body>
					</Table>
					
				</Container>
			</>
		)
	}
}

const mapStateToProps = state => ({
	config: state.config.config
})

export default connect(
	mapStateToProps,
	null
)(AgentCommission)
