import React from 'react'
import ReactDOM from 'react-dom'
import App from './screens/App'
import * as serviceWorker from './serviceWorker'

import 'semantic-ui-less/semantic.less'

function startApp() {
	
	if (window.cordova) {
		window.open = window.cordova.InAppBrowser.open;
	}
	
	ReactDOM.render(<App />, document.getElementById('root'))
}

if (window.cordova) {
	document.addEventListener('deviceready', () => {
		startApp();
	}, false);
} else {
	startApp();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()