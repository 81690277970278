import React, { Component } from 'react'
import { connect } from 'react-redux'

import ModalBox from '../../components/ModalBox'

class VersionNumber extends Component {
	
	constructor(props) {
		
		super(props)
		
		let cv = this.props.config.attributes.version;
		
		let	currentVersion = cv ? cv : '0.0.1',
			appVersion = currentVersion
		;
		
		this.state = {
			currentVersion: currentVersion,
			appVersion: appVersion
		}
	}
	componentDidMount() {
		if (window.cordova) {
			window.cordova.getAppVersion.getVersionNumber().then(function (ver) {
				this.setState({ appVersion: ver});
			}.bind(this));
		}
	}
	
	versionAlert = () => {
		
		if (this.props.config.attributes.update_version === 'true') {
			
			const { currentVersion, appVersion } = this.state;
			
			if (window.cordova && currentVersion !== appVersion) {
				
				let content = 'A newer version of this app is available. v' + this.state.currentVersion,
					options = this.props.config.getElementsByTagName('OPTIONS')[0],
					href = options.getElementsByTagName('ANDROID_URL')[0].value
				;
				
				if (window.cordova.platformId === 'ios') {
					href = options.getElementsByTagName('IOS_URL')[0].value
				}
				
				return (
					<ModalBox
						open
						headerIcon='download'
						headerContent='Update Now'
						content={content}
						href={href}
					/>
				);
			}
		}
		return null;
	}
	
	render() {
		
		const { appVersion } = this.state;
		
		let styles = {
			textAlign: 'center', 
			marginTop: '2em',
			fontSize: '0.7em',
			letterSpacing: '0.16em'
		};
		
		if (this.props.inverted === true) {
			styles.color = 'rgba(255, 255, 255, 0.9)';
		}
		
		return (
			<>
				<div style={ styles }>{ appVersion }</div>
				{ this.versionAlert() }
			</>
		);
	}
}

const mapStateToProps = state => ({
	config: state.config.config
})

export default connect(
	mapStateToProps,
	null
)(VersionNumber)