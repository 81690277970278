import React, { Component } from 'react';
import { connect } from 'react-redux'
import axios from 'axios'

import { Button, Form, Icon, Input, Message, TextArea } from 'semantic-ui-react'

const DEFAULT_STATE = {
	loading: false,
	values: {
		name: '',
		email: '',
		telephone: '',
		message: ''
	},
	error: false,
	success: false,
	errors: {}
}

class ContactForm extends Component {
	
	state = DEFAULT_STATE;
	
	handleSubmit = async (e) => {
		
		this.setState({ loading: true });
		
		this.setState({ error: false });
		this.setState({ success: false });
		
		let { name, email, message } = this.state.values,
			errors = {}
		;
		
		if (name === undefined) 	{ name = '' }
		if (email === undefined) 	{ email = '' }
		if (message === undefined) 	{ message = '' }
		
		if (!email.includes('@') && email !== '') { errors.email = { pointing: 'above', content: '@ not included in email' } }
		
		if (!name) 		{ errors.name = 	{ pointing: 'above', content: 'Name is required' } }
		if (!email) 	{ errors.email = 	{ pointing: 'above', content: 'Email is required' } }
		if (!message) 	{ errors.message = 	{ pointing: 'above', content: 'Message is required' } }
		
		this.setState({ errors: errors });
		
		if (Object.keys(errors).length === 0 && errors.constructor === Object) {
			
			try {
				
				let url = this.props.config.configRoot + '/config.php';
				
				let { values } = this.state;
				
				values.request = 'email';
				
				const 	r = await axios.post(url, values, {headers: {'content-type': 'application/json'}}),
						response = r.data
				;
				
				if (response.success === false && response.msg.length > 0) {
					throw response.msg;
				}
				
				this.setState(prevState => (DEFAULT_STATE));
				this.setState({ success: true });
				
			} catch (error) {
				this.setState({ error: true });
			}
		}
		this.setState({ loading: false });
	}
	
	handleChange = (e, data) => {
		
		let values = this.state.values,
			updateValues = {
			name: values.name,
			email: values.email,
			telephone: values.telephone,
			message: values.message
		};
		
		Object.keys(updateValues).forEach(function(key) {
			if (data.name === key) {
				updateValues[key] = e.target.value;
			}
		});
		
		this.setState({ values: updateValues });
	}
	
	render() {
		
		const { loading, values, error, success, errors } = this.state
		
		return (
			<Form inverted onSubmit={this.handleSubmit} error={error} success={success}>
				<Form.Field
					name='name'
					control={Input}
					label='Your Name'
					placeholder='Your Name'
					error={errors.name}
					onChange={this.handleChange}
					value={values.name}
				/>
				<Form.Field
					name='email'
					control={Input}
					label='Your Email Address'
					placeholder='Your Email Address'
					error={errors.email}
					onChange={this.handleChange}
					value={values.email}
				/>
				<Form.Field
					name='telephone'
					control={Input}
					label='Your Telephone Number'
					placeholder='Your Telephone Number'
					error={errors.telephone}
					onChange={this.handleChange}
					value={values.telephone}
				/>
				<Form.Field
					name='message'
					control={TextArea}
					label='Your Message'
					placeholder='Your Message'
					error={errors.message}
					onChange={this.handleChange}
					value={values.message}
				/>
				<Message
					error
					header='We are sorry'
					content='Your submission could not be sent'
				/>
				<Message
					success
					header='Thank you'
					content='We will be in contact soon'
				/>
				<Button icon labelPosition='left' color="green" type='submit' loading={loading} disabled={loading}>
					<Icon name='envelope' />
					Send
				</Button>
			</Form>
		);
	}
}

const mapStateToProps = state => ({
	config: state.config.config
})

export default connect(
	mapStateToProps,
	null
)(ContactForm)