import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Button, Container, Divider, Form, Header, Icon, Label, Table } from 'semantic-ui-react'

import formatNumber from '../../../utils/formatNumber'
import NumberField from '../../../components/NumberField'

const DEFAULT_STATE = {
	calculating: false,
	values: {
		bondAmount: 0
	},
	results: {
		deedsOfficeFee: { title: 'Deeds Office Fee', value: 0, display: formatNumber(0, true) },
		bondFee: 		{ title: 'Bond Fee', value: 0, display: formatNumber(0, true) },
		sundries: 		{ title: 'Sundries', value: 0, display: formatNumber(0, true) },
		VAT: 			{ title: 'VAT', value: 0, display: formatNumber(0, true) },
		total: 			{ title: 'Total', value: 0, display: formatNumber(0, true) }
	}
}

class BondRegistrationCosts extends Component {
	
	state = DEFAULT_STATE;
	
	handleSubmit = () => {
		
		this.setState({ calculating: true });
		
		let values = this.state.values;
		
		let config = this.props.config.getElementsByTagName('ALL')[0];
		
		// STATIC figures NOT needed for calculations
		
		let Sundries = parseFloat(config.getElementsByTagName('SUNDRIES')[0].value);
		
		// STATIC figures needed for calculations
		
		let VATpercent = parseFloat(config.getElementsByTagName('VAT')[0].value);
		
		// INPUT figures needed for calculations
		
		let BondAmount = parseFloat(values.bondAmount);
		
		// CALCULATIONS
		
		// Deeds Office Fee
		
		let DeedsOfficeFee = 0;
		
		if (100000 >= BondAmount) {
			DeedsOfficeFee = 39;
		} else if ((100000 < BondAmount) && (BondAmount <= 200000)) {
			DeedsOfficeFee = 86;
		} else if ((200000 < BondAmount) && (BondAmount <= 300000)) {
			DeedsOfficeFee = 539;
		} else if ((300000 < BondAmount) && (BondAmount <= 600000)) {
			DeedsOfficeFee = 673;
		} else if ((600000 < BondAmount) && (BondAmount <= 800000)) {
			DeedsOfficeFee = 946;
		} else if ((800000 < BondAmount) && (BondAmount <= 1000000)) {
			DeedsOfficeFee = 1086;
		} else if ((1000000 < BondAmount) && (BondAmount <= 2000000)) {
			DeedsOfficeFee = 1220;
		} else if ((2000000 < BondAmount) && (BondAmount <= 4000000)) {
			DeedsOfficeFee = 1691;
		} else if ((4000000 < BondAmount) && (BondAmount <= 6000000)) {
			DeedsOfficeFee = 2051;
		} else if ((6000000 < BondAmount) && (BondAmount <= 8000000)) {
			DeedsOfficeFee = 2442;
		} else if ((8000000 < BondAmount) && (BondAmount <= 10000000)) {
			DeedsOfficeFee = 2854;
		} else if ((10000000 < BondAmount) && (BondAmount <= 15000000)) {
			DeedsOfficeFee = 3397;
		} else if ((15000000 < BondAmount) && (BondAmount <= 20000000)) {
			DeedsOfficeFee = 4080;
		} else if (20000000 < BondAmount) {
			DeedsOfficeFee = 5435;
		}
		
		// Bond Fee
		
		let BondFee = 0;
		
		if (100000 >= BondAmount) {
			BondFee = 5200;
		} else if ((100000 < BondAmount) && (BondAmount <= 500000)) {
			BondFee = Math.ceil((BondAmount - 100000) / 50000) * 800 + 5200;
		} else if ((500000 < BondAmount) && (BondAmount <= 1000000)) {
			BondFee = Math.ceil((BondAmount - 500000) / 100000) * 1600 + 11600;
		} else if ((1000000 < BondAmount) && (BondAmount <= 5000000)) {
			BondFee = Math.ceil((BondAmount - 1000000) / 200000) * 1600 + 19600;
		} else if (5000000 < BondAmount) {
			BondFee = Math.ceil((BondAmount - 5000000) / 500000) * 2000 + 51600;
		}
		
		// Vat
		
		let VAT = BondFee * VATpercent / 100;
		
		// Total
		
		let Total = DeedsOfficeFee + BondFee + Sundries + VAT;
		
		let updatedResults = {
			deedsOfficeFee: { title: 'Deeds Office Fee', 	value: DeedsOfficeFee, 	display: formatNumber(DeedsOfficeFee, true) },
			bondFee: 		{ title: 'Bond Fee', 			value: BondFee, 		display: formatNumber(BondFee, true) },
			sundries: 		{ title: 'Sundries', 			value: Sundries, 		display: formatNumber(Sundries, true) },
			VAT: 			{ title: 'VAT', 				value: VAT, 			display: formatNumber(VAT, true) },
			total: 			{ title: 'Total', 				value: Total, 			display: formatNumber(Total, true) }
		}
		
		this.setState({ results: updatedResults });
		this.setState({ calculating: false });
	}
	refreshForm = () => {
		this.setState(prevState => (DEFAULT_STATE));
	}
	handleChange = (e, data) => {
		
		let updateValues = {
			bondAmount: this.state.values.bondAmount
		};
		let submitYN = true;
		
		if (data.name === 'BondAmount') {
			
			updateValues.bondAmount = e.target.value;
			submitYN = false;
			
		}
		
		this.setState(prevState => ({ values: updateValues }), () => submitYN === true ? this.handleSubmit() : null);
	}
	
	render() {
		
		const { calculating, values, results } = this.state;
		
		if (window.cordova) {
			document.onkeypress = function (e) {
				e = e || window.event;
				if (e.keyCode === 13) {
					window.Keyboard.hide();
				}
			};
		}
		
		return (
			
			<>
				<Container text style={{ color: "#fff", flex: 1, padding: "2em 0" }}>
					
					<Header as="h1" inverted style={{ marginbottom: "1em" }}>Bond Registration Costs Calculator</Header>
					
					<Form inverted onSubmit={this.handleSubmit} onReset={this.refreshForm}>
						<Form.Field>
							<NumberField
								name="BondAmount"
								value={values.bondAmount ? values.bondAmount : ''}
								placeholder="Bond Amount"
								label="Bond Amount"
								labelPosition="right"
								labelStart="R"
								onChange={this.handleChange}
							/>
						</Form.Field>
						<Button.Group>
							<Button icon labelPosition="left" color="grey" type="reset">
								<Icon name="arrow left" />
								Clear
							</Button>
							<Button icon labelPosition="left" color="green" type="submit" loading={calculating}>
								<Icon name="calculator" />
								Calculate
							</Button>
						</Button.Group>
					</Form>
					
					<Divider horizontal inverted style={{ margin: "2em 0" }}>Results</Divider>
					
					<Table celled unstackable singleLine size="small">
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell></Table.HeaderCell>
								<Table.HeaderCell>Cost</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							
							{Object.keys(results).map((key, index) => {
								return <Table.Row key={index}>
											<Table.Cell>
												<Label ribbon color="green">{ results[key].title }</Label>
											</Table.Cell>
											<Table.Cell><strong>{ results[key].display }</strong></Table.Cell>
										</Table.Row>
							})}
							
						</Table.Body>
					</Table>
					
				</Container>
			</>
		)
	}
}

const mapStateToProps = state => ({
	config: state.config.config
})

export default connect(
	mapStateToProps,
	null
)(BondRegistrationCosts)