import React, { Component } from 'react';
import { connect } from 'react-redux'

import { Button, Icon } from 'semantic-ui-react'

//import { Mail, Facebook, Twitter } from 'react-social-sharing'

class ShareButton extends Component {
	
	socialSharing = () => {
		
		let shareOptions = this.props.config.getElementsByTagName('SHARE_OPTIONS')[0];
		
		let options = {
			message: shareOptions.getElementsByTagName('MESSAGE')[0].value, // not supported on some apps (Facebook, Instagram)
			subject: shareOptions.getElementsByTagName('SUBJECT')[0].value, // fi. for email
			url: this.props.config.getElementsByTagName('ANDROID_URL')[0].value,
			//files: ['', ''], // an array of filenames either locally or remotely
			//chooserTitle: '', // Android only, you can override the default share sheet title
			//appPackageName: '', // Android only, you can provide id of the App you want to share with
			//iPadCoordinates: '0,0,0,0' //IOS only iPadCoordinates for where the popover should be point.  Format with x,y,width,height
		};
		
		if (window.cordova && window.cordova.platformId === 'ios') {
			options.url = this.props.config.getElementsByTagName('IOS_URL')[0].value;
		}
		
		let onSuccess = function(result) {
			console.log("Share completed? " + result.completed); // On Android apps mostly return false even while it's true
			console.log("Shared to app: " + result.app); // On Android result.app since plugin version 5.4.0 this is no longer empty. On iOS it's empty when sharing is cancelled (result.completed=false)
		};
		
		let onError = function(msg) {
			console.log("Sharing failed with message: " + msg);
		};
		
		window.plugins.socialsharing.shareWithOptions(options, onSuccess, onError);
	}
	
	render() {
		
		let styles = {
			display: 'block',
			width: '120px'
		};
		
		if (this.props.center === true) {
			styles.margin = '30px auto 0';
		}
		
		if (window.cordova) {
			return (
				<Button icon type='button' labelPosition='left' color={ this.props.color ? this.props.color : 'red' } onClick={ this.socialSharing } style={ styles }>
					<Icon name='share' />
					Share
				</Button>
			);
		}
		return null;
	}
}

const mapStateToProps = state => ({
	config: state.config.config
})

export default connect(
	mapStateToProps,
	null
)(ShareButton)