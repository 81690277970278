import React, { Component } from 'react';
import { Form, Input, Label } from 'semantic-ui-react'

class NumberField extends Component {
	
	constructor(props) {
		super(props)
		this.state = { isEditing: false, currentValue: this.props.value }
	}
	componentDidUpdate(prevProps) {
		if (this.props.value !== prevProps.value) {
			this.setState({currentValue: this.props.value});
		}
		if (this.input) {
			this.input.focus();
		}
	}
	handleChange = (e, data) => {
		this.setState({currentValue: e.target.value});
		this.props.onChange(e, data);
	}
	toCurrency = (number) => {
		const formatter = new Intl.NumberFormat("sv-SE", { style: "decimal" });
		return formatter.format(number);
	}
	toggleEditing = () => {
		this.setState({ isEditing: !this.state.isEditing });
	}
	
	render() {
		
		const { currentValue } = this.state
		
		return (
			<Form.Field>
				<label>{ this.props.label } </label>
				{this.state.isEditing ? (
					<Input
						type='number'
						name={this.props.name}
						placeholder={this.props.placeholder}
						value={currentValue}
						onChange={this.handleChange.bind(this)}
						onBlur={this.toggleEditing.bind(this)}
						autoComplete='off'
						pattern='\d*'
						autoFocus
						ref={ref => this.input = ref}
						labelPosition={this.props.labelPosition}
					>
						{ this.props.labelStart ? <Label color="grey">{ this.props.labelStart }</Label> : '' }
						<input />
						{ this.props.labelEnd ? <Label color="grey">{ this.props.labelEnd }</Label> : '' }
					</Input>
				) : (
					<Input
						type='text'
						name={this.props.name}
						placeholder={this.props.placeholder}
						value={currentValue ? this.toCurrency(currentValue) : ''}
						onFocus={this.toggleEditing.bind(this)}
						labelPosition={this.props.labelPosition}
					>
						{ this.props.labelStart ? <Label color="grey">{ this.props.labelStart }</Label> : '' }
						<input />
						{ this.props.labelEnd ? <Label color="grey">{ this.props.labelEnd }</Label> : '' }
					</Input>
				)}
			</Form.Field>
		);
	}
}

export default NumberField;