import React from 'react'
import { Link, Router, Route, Switch, Redirect } from 'react-router-dom'
import { createHashHistory as createHistory } from 'history'
import { Provider } from 'react-redux'
import axios from 'axios'

import store from '../redux/store'
import { setConfig } from '../redux/actions/configActions'

import { Container, Dimmer, Icon, Image, Loader, Menu, Segment, Sidebar } from 'semantic-ui-react'

import Dash from './Dash'

import ConveyancingCosts from '../screens/calculators/ConveyancingCosts'
import BondRegistrationCosts from '../screens/calculators/BondRegistrationCosts'
import BondRepayments from '../screens/calculators/BondRepayments'

import ExtraPayments from '../screens/calculators/ExtraPayments'
import AgentCommission from '../screens/calculators/AgentCommission'

import Contact from './Contact'
import About from './About'
import Terms from './Terms'

import ShareButton from '../components/ShareButton'
import VersionNumber from '../components/VersionNumber'

export const history = createHistory()

class App extends React.Component {
	
	constructor(props) {
		super(props)
		this.state = { loading: true, loadingMsg: 'Bringing Property to the People', visible: false }
		this._bootstrap();
	}
	componentDidMount() {
		this.logoSrc();
	}
	_bootstrap = async () => {
		
		try {
			
			let configRoot = 'https://mgfattorneys.co.za/config',
				url = configRoot + '/config.php'
			;
			const 	r = await axios.post(url, {'request': 'config'}, {headers: {'content-type': 'application/json'}}),
					response = r.data
			;
			
			if (response.success === false && response.msg.length > 0) {
				throw response.msg;
			}
			
			var XMLParser = require('react-xml-parser');
			var config = new XMLParser().parseFromString(response.config);
			
			config.downloads = response.downloads;
			config.configRoot = configRoot;
			
			store.dispatch(setConfig(config))
			
			this.stopLoading();
			
		} catch (error) {
			this.setState({ loadingMsg: error })
			return
		}
		
	}
	
	stopLoading = () => {
		this.setState({ loading: false, loadingMsg: '' })
	}
	navigateHistory = () => {
		history.goBack()
	}
	toggleSidebar = () => {
		this.setState(prevState => {
			return { visible: !prevState.visible }
		})
	}
	closeSidebar = () => {
		if (this.state.visible === true) {
			this.setState({ visible: false })
		}
	}
	logoSrc = () => {
		
		if (history.location.pathname === '/' || history.location.pathname === '/dash' || history.location.pathname === '/downloads') {
			this.setState({ logoSrc: './assets/logo-full.png' })
			this.setState({ logoSize: 'small' })
		} else {
			this.setState({ logoSrc: './assets/logo.png' })
			this.setState({ logoSize: 'tiny' })
		}
	}
	
	render() {
		
		const { loading, loadingMsg, visible, logoSrc, logoSize } = this.state
		
		history.listen((location, action) => {
			this.logoSrc();
		});
		
		if (loading) {
			return (
				<Dimmer active>
					<Loader>{loadingMsg}</Loader>
				</Dimmer>
			)
		}
		if (history.location.pathname.includes('/wrapperless/')) {
			return (
				<>
					<Provider store={store}>
						<Router history={history}>
							<Switch>
								<Route exact path="/wrapperless/" component={Dash} />
								
								<Route exact path="/wrapperless/calculator/bond-repayments" component={BondRepayments} />
								<Route exact path="/wrapperless/calculator/bond-registration-costs" component={BondRegistrationCosts} />
								<Route exact path="/wrapperless/calculator/conveyancing-costs" component={ConveyancingCosts} />
								
								<Route exact path="/wrapperless/calculator/agent-commission" component={AgentCommission} />
								<Route exact path="/wrapperless/calculator/extra-payments" component={ExtraPayments} />
								
								<Route exact path="/wrapperless/contact" component={Contact} />
								<Route exact path="/wrapperless/about" component={About} />
								<Route exact path="/wrapperless/terms" component={Terms} />
								
								<Redirect to="/wrapperless/" />
							</Switch>
						</Router>
					</Provider>
				</>
			)
		}
		return (
			<>
				<Provider store={store}>
					<Router history={history}>
						<Sidebar.Pushable>
							<Sidebar
								as={Menu}
								inverted
								vertical
								animation='push'
								direction='right'
								onHide={() => this.toggleSidebar}
								visible={visible}
							>
								<Menu.Item as={Link} to="/dash" onClick={this.closeSidebar}><Icon name="th" size="large" /> Dashboard</Menu.Item>
								
								<Menu.Item as={Link} to="/calculator/conveyancing-costs" onClick={this.closeSidebar}><Icon name="calculator" size="large" /> Transfer Costs Calculator</Menu.Item>
								<Menu.Item as={Link} to="/calculator/bond-registration-costs" onClick={this.closeSidebar}><Icon name="calculator" size="large" /> Bond Registration Costs Calculator</Menu.Item>
								<Menu.Item as={Link} to="/calculator/bond-repayments" onClick={this.closeSidebar}><Icon name="calculator" size="large" /> Bond Repayments Calculator</Menu.Item>
								
								<Menu.Item as={Link} to="/calculator/extra-payments" onClick={this.closeSidebar}><Icon name="calculator" size="large" /> Extra Payments Calculator</Menu.Item>
								<Menu.Item as={Link} to="/calculator/agent-commission" onClick={this.closeSidebar}><Icon name="calculator" size="large" /> Agent Commission Calculator</Menu.Item>
								
								<Menu.Item as={Link} to="/contact" onClick={this.closeSidebar}><Icon name="mail" size="large" /> Contact Us</Menu.Item>
								<Menu.Item as={Link} to="/about" onClick={this.closeSidebar}><Icon name="address book outline" size="large" /> About Us</Menu.Item>
								<Menu.Item as={Link} to="/terms" onClick={this.closeSidebar}><Icon name="compass outline" size="large" /> Terms of Use</Menu.Item>
								
								<ShareButton center />
								
								<VersionNumber inverted />
								
							</Sidebar>
							<Sidebar.Pusher dimmed={visible} onClick={this.closeSidebar}>
								<Menu borderless>
									<Container style={{ justifyContent: "space-between" }}>
										<Menu.Item as="a" onClick={this.navigateHistory}>
											<Icon name="arrow left" size="large" />
										</Menu.Item>
										<Menu.Item as={Link} to="/">
											<Image
												src={logoSrc}
												size={logoSize}
											/>
										</Menu.Item>
										<Menu.Item onClick={this.toggleSidebar}>
											<Icon name="bars" size="large" />
										</Menu.Item>
									</Container>
								</Menu>
								
								<Switch>
									<Route exact path="/" component={Dash} />
									
									<Route exact path="/calculator/bond-repayments" component={BondRepayments} />
									<Route exact path="/calculator/bond-registration-costs" component={BondRegistrationCosts} />
									<Route exact path="/calculator/conveyancing-costs" component={ConveyancingCosts} />
									
									<Route exact path="/calculator/agent-commission" component={AgentCommission} />
									<Route exact path="/calculator/extra-payments" component={ExtraPayments} />
									
									<Route exact path="/contact" component={Contact} />
									<Route exact path="/about" component={About} />
									<Route exact path="/terms" component={Terms} />
									
									<Redirect to="/" />
								</Switch>
								
								<Container 
									textAlign="center" 
									style={{ color: "rgba(255, 255, 255, 0.9)" }} 
									onClick={() => { window.open("https://www.blueboxonline.com/", "_system") }}
								>
									App Development by
									<Image
										centered
										src="./assets/bluebox.png"
										size="tiny"
									/>
								</Container>
								
								<Segment>
									<Container textAlign="center">
										MGF Attorneys <Icon name="copyright outline" />
									</Container>
								</Segment>
							</Sidebar.Pusher>
						</Sidebar.Pushable>
					</Router>
				</Provider>
			</>
		)
	}
}

export default App